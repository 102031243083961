
<template>
  <div class="wrapper">
    <div id=mytime>{{str}}</div>
    <!-- <input id="mytime" type="text" name="" value="显示时间"> -->
  </div>
 
 
</template>
<script>
  export default {
    data(){
      return {
        h:0,//定义时，分，秒，毫秒并初始化为0；
        m:0,
        ms:0,
        s:0,
        time:0,
        str:'00:00:00',
        mytime:''
      }
    },
    methods:{
      timer(){  //定义计时函数
        this.ms=this.ms+50;         //毫秒
        if(this.ms>=1000){
          this.ms=0;
          this.s=this.s+1;         //秒
        }
        if(this.s>=60){
          this.s=0;
          this.m=this.m+1;        //分钟
        }
        if(this.m>=60){
          this.m=0;
          this.h=this.h+1;        //小时
        }
        this.str =this.toDub(this.h)+":"+this.toDub(this.m)+":"+this.toDub(this.s)+""/*+this.toDubms(this.ms)+"毫秒"*/;
        // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
      },
  
      reset(){  //重置
        clearInterval(this.time);
        this.h=0;
        this.m=0;
        this.ms=0;
        this.s=0;
        this.str="00:00:00";
      },
  
      start(startDuration){  //开始
        clearInterval(this.time)
        if(startDuration > 0){// 已经运动的时长
          this.formatSeconds(startDuration);
        }
        this.time=setInterval(this.timer,50);
      },
  
      stop(){  //暂停
        clearInterval(this.time);
      },
  
      toDub(n){  //补0操作
        if(n<10){
          return "0"+n;
        }
        else {
          return ""+n;
        }
      },
  
      toDubms(n){  //给毫秒补0操作
        if(n<10){
          return "00"+n;
        }
        else {
          return "0"+n;
        }
    
      },

      //  秒数转化为时分秒
      formatSeconds(value) {
        //  秒
        let second = parseInt(value)
        //  分
        let minute = 0
        //  小时
        let hour = 0
        //  天
        //  let day = 0
        //  如果秒数大于60，将秒数转换成整数
        if (second > 60) {
          //  获取分钟，除以60取整数，得到整数分钟
          minute = parseInt(second / 60)
          //  获取秒数，秒数取佘，得到整数秒数
          second = parseInt(second % 60)
          //  如果分钟大于60，将分钟转换成小时
          if (minute > 60) {
            //  获取小时，获取分钟除以60，得到整数小时
            hour = parseInt(minute / 60)
            //  获取小时后取佘的分，获取分钟除以60取佘的分
            minute = parseInt(minute % 60)
            //  如果小时大于24，将小时转换成天
            //  if (hour > 23) {
            //    //  获取天数，获取小时除以24，得到整天数
            //    day = parseInt(hour / 24)
            //    //  获取天数后取余的小时，获取小时除以24取余的小时
            //    hour = parseInt(hour % 24)
            //  }
          }
        }
        this.h = hour
        this.m = minute
        this.s = second
        // let result = '' + parseInt(second) + '秒'
        // if (minute > 0) {
        //   result = '' + parseInt(minute) + '分' + result
        // }
        // if (hour > 0) {
        //   result = '' + parseInt(hour) + '小时' + result
        // }
        //  if (day > 0) {
        //    result = '' + parseInt(day) + '天' + result
        //  }
        // console.log('result：', result)
        // return result

      }
    },
    //实例创建完成后调用，此阶段完成了数据的观测等，但尚未挂载，$el 还不可用。需要初始化处理一些数据时会比较有用
    created () {
 
    },
    beforeDestroy () {
      clearInterval(this.time)
    },
  }
</script>
<style scoped>
  #mytime{
    font-family: 'KenyanCoffeeRg-BoldItalic';
    font-size: 37.5px;
    color: #2b2b2b;
  }
</style>