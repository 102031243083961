<template>
  <div class="braceletHome">
    <div class="topTitle">
      <img :src="avatarUrl"
           alt="">
      <div @click="goTo('/myProfile')"
           class="info">
        <div class="name" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ userInfo ? userInfo.nickname : '' }}</div>
        <div class="id">ID : {{ userInfo ? userInfo.id : '' }}</div>
      </div>
      <img class="navto1"
           @click="show = true"
           src="@/assets/bracelet_img/lang.png"
           alt="">
      <img class="navto"
           @click="goTo('/navbar')"
           src="@/assets/bracelet_img/navto.png"
           alt="">
    </div>

    <img class="tupian"
         src="@/assets/bracelet_img/21.png"
         alt="">

    <div class="box">

      <div class="rank_integral">
        <div class="top">
          <div class="one">
            <div class="rank" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('排行')}}</div>
            <!-- <div class="label">-20</div> -->
          </div>
          <div class="two">
            <div class="integral" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('积分')}}</div>
            <!-- <div class="label">+200</div> -->
          </div>
        </div>
        <div class="btm">
          <div class="left">{{ userInfo.ionz_ranking == 0 ? '100+' : userInfo.ionz_ranking }}</div>
          <div class="right">{{ userInfo.all_money || 0 }}</div>
        </div>
      </div>

      <!-- <div class="watch">
          <div class="label">已断开链接</div>
          <div class="status">手表状态</div>
          <div class="watchName">手表007</div>
          <div class="tongbu">上次同步 :  10 JULY 2022 | 09:00 A.M.</div>
          <div class="btn_box">
            <div class="tongbudata btn">同步数据</div>
            <div class="disconnect btn">断开连接</div>
          </div>
          <img class="img" src="@/assets/bracelet_img/24.png" alt="">
        </div> -->
      <!-- <div class="watch">
        <div class="status">{{$t('NFT收藏')}}</div>
        <van-list v-model="loading"
                  :finished="finished"
                  :finished-text="$t('没有更多了')"
                  :loading-text="$t('加载中...')"
                  :immediate-check="false"
                  @load="onLoad"
                  class="list">
         
          <div class="item"
               :class="{'item_act': item.use_state == 1}"
               @click="nftChange(item.id)"
               v-for="(item, index) in list"
               :key="index">
             <div class="new">新</div>
            <div class="name">{{ item.name }}</div>
            <div class="cocobern">COCOBERN</div>
            <div class="cocobern">LvL.{{ item.grade }}</div>
            <img class="img"
                 :src="item.cover"
                 alt="">
          </div>
           </div> 
        </van-list>
      </div> -->

      <div class="distance">
        <div class="status" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('探索距离')}}</div>
        <img class="bg"
             src="@/assets/bracelet_img/23.png"
             alt="">
        <div class="main">
          <div @click="goTo('/stepsNumber')"
               class="item">
            <div class="num">{{ motionData.steps ? motionData.steps : 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/17.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('步数')}}</div>
            </div>
          </div>
          <div @click="goTo('/heartRate')"
               class="item">
            <div class="num">{{ motionData.heart_rate ? motionData.heart_rate : 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/15.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('心率')}}(BPM)</div>
            </div>
          </div>
          <div @click="goTo('/calorie')"
               class="item">
            <div class="num">{{ motionData.calorie ? motionData.calorie : 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/16.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('卡路里')}}(KCAL)</div>
            </div>
          </div>
          <div class="item">
            <div class="num">{{ motionData.duration ? motionData.duration : 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/19.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('累计时长(分钟)')}}</div>
            </div>
          </div>
          <!-- <div class="item">
            <positive-time ref="sportTime" />
            <div class="row">
              <img src="@/assets/bracelet_img/19.png"
                   alt="">
              <div>{{$t('实时运动')}}</div>
            </div>
          </div> -->
          <!-- <div class="item">
            <div class="num">{{ sportData.durable ? sportData.durable : 0 }}/100</div>
            <div class="row">
              <img src="@/assets/bracelet_img/18.png"
                   alt="">
              <div>{{$t('耐用度')}}</div>
            </div>
          </div> -->
          <!-- <van-button v-if="startShow"
                      v-show="list.length > 0"
                      @click="startMoving"
                      :loading="startLoading"
                      class="btn top_btn">{{$t('开始运动')}}</van-button>
          <van-button v-else
                      v-show="list.length > 0"
                      @click="endMoving"
                      :loading="endLoading"
                      class="btn top_btn">{{$t('结束运动')}}</van-button> -->
          <!-- <div class="btn btm_btn">恢复耐久</div> -->
          <van-button class="btn btm_btn"
                      :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                      v-show="list.length > 0"
                      :loading="regainLoading"
                      @click="regainWear">{{$t('恢复耐久')}}</van-button>
        </div>
      </div>

      <div class="datePreset">
        <div class="status" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('日期预设')}}</div>
        <div class="box">
          <div class="calendar">
            <div class="braceletHomeTitle">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ month }}{{$t('月')}}{{ year }}</div>
              <img @click="dateShow = true"
                   class="pointer"
                   src="@/assets/bracelet_img/25.png"
                   alt="">
            </div>
            <Calendar ref="Calendar"
                      :textTop="['SU','MO', 'TU', 'WE', 'TH', 'FR', 'SA']"
                      :sundayStart='true'
                      v-on:choseDay="clickDay"></Calendar>
          </div>
        </div>

        <div class="time">
          <div class="braceletHomeTitle" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ month }}{{$t('月')}}{{ day }}{{$t('日')}} {{ year }}</div>
          <!-- <div class="item mar_btm_15">
              <div class="line"></div>
              <div class="right">
                <div class="text">当时户外跑步</div>
                <div class="dt">00:02 - 01:30</div>
              </div>
            </div> -->
          <div class="item">
            <div class="line"></div>
            <div class="right">
              <div class="text" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('户外跑步总记录')}}</div>
              <div class="dt" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ allSteps }}({{$t('步数')}})</div>
            </div>
          </div>
        </div>
        <div @click="goTo('/activityReport')"
             class="report" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('查看活动报告')}}></div>
      </div>
    </div>

    <div class="footBtm">
      <div class="left">
        <div class="box">
          <img src="@/assets/bracelet_img/5.png"
               alt="">
        </div>
      </div>
      <div class="right">
        <div @click="goTo('/market')"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
             class="ask">{{$t('交易市场')}}</div>
        <div @click="goTo('/roomHome')"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
             class="invite">{{$t('社交挖矿')}}</div>
      </div>
    </div>

    <!-- 月份弹出层 -->
    <van-popup v-model="dateShow"
               position="bottom">
      <van-datetime-picker v-model="currentDate"
                           @confirm="confirmDate"
                           @cancel="cancelDate"
                           :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                           :confirm-button-text="$t('确定')"
                           :cancel-button-text="$t('取消')"
                           type="year-month"
                           :title="$t('选择年月')" />
    </van-popup>

    <van-popup class="pop-up"
               v-model="show">
      <div class="pop-up-box">
        <van-radio-group v-model="radio"
                         checked-color="#7c4283">
          <van-radio class="pop-box-radio"
                     name="cn">中文</van-radio>
          <van-radio class="pop-box-radio"
                     name="en">English</van-radio>
          <van-radio class="pop-box-radio"
                     name="kr">한국어</van-radio>
          <van-radio class="pop-box-radio"
                     name="vn">ViệtName</van-radio>
          <van-radio class="pop-box-radio"
                     name="my">Malay</van-radio>
          <van-radio class="pop-box-radio"
                     name="id">IndonesiaName</van-radio>
        </van-radio-group>
      </div>
      <div class="pop-up-btn">
        <button class="pop-btn-btn btn-cancel-col"
                @click="cancel">{{$t('取消')}}</button>
        <button class="pop-btn-btn btn-determine-col"
                @click="setLang">{{$t('确定')}}</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { userInfo, getMyNftList, nftSwitch, Sportdata, getWatchInfo, startSport, getDateList, nftRepair, sportEnd, nftSportState, mainConfig, language } from '@/api/api'
import { erc20Abi } from '../../utils/abi'
import { mapState } from 'vuex'
import Calendar from "vue-calendar-component";
import positiveTime from '@/components/positiveTime/index.vue'
export default {
  components: {
    Calendar,
    positiveTime
  },
  data () {
    return {
      userInfo: {},
      avatarUrl: '',
      currentDate: '',
      dateShow: false,
      timer: null,
      year: new Date().getFullYear(), //获取完整的年份(4位)
      month: new Date().getMonth() + 1, //获取当前月份(0-11,0代表1月)
      day: new Date().getDate(), //获取当前日(1-31)

      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      list: [],

      changeNftId: 0,
      sportData: {
        heart_rate: '',
        steps: '',
        calorie: '',
        duration: '',
        durable: '',
        top: '',
      },
      motionData: {
        heart_rate: '',
        steps: '',
        calorie: '',
        duration: '',
        durable: '',
        top: '',
      },
      startLoading: false,
      endLoading: false,
      regainLoading: false,
      allSteps: 0,
      startShow: true,
      show: false,
      radio: '',
      motionId: '',
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { this.waitForGlobal(); },
  methods: {
    regainWear () {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('修复中...'), //修复中...
      });
      this.regainLoading = true
      mainConfig().then(res => {
        if (res.code == 0) {
          nftRepair({ id: this.changeNftId }).then(resu => {
            if (resu.code == 0) {
              let value = new this.$Bignumber(resu.data.val * Math.pow(10, res.data.nft_repair_accuracy));
              let tokenContract = new this.web3.eth.Contract(erc20Abi, res.data.nft_repair_token);
              tokenContract.methods.transfer(res.data.nft_repair_address, this.web3.utils.fromDecimal(value)).send({ from: localStorage.getItem('defaultAddress') })
                .then((resuq) => {
                  console.log('转账成功了吗-----', resuq);
                  this.regainLoading = false
                  toast.clear();
                  this.$toast(this.$t('修复成功'));
                })
                .catch((err) => {
                  console.log('转账失败了吗-----', err);
                  this.regainLoading = false
                  toast.clear();
                  this.$toast(this.$t('修复失败'));
                })
            } else {
              this.regainLoading = false
              toast.clear();
              this.$toast(resu.msg);
            }
          })
        } else {
          this.regainLoading = false
          toast.clear();
        }
      })
    },
    getPlanInfo (date) {
      getDateList({ date }).then(res => {
        if (res.code == 0) {
          this.allSteps = res.data.step_count_begin
        }
      })
    },
    startMoving () {
      this.startLoading = true
      startSport({
        nft_id: this.changeNftId
      }).then(res => {
        if (res.code == 0) {
          this.startShow = false
          this.$refs.sportTime.reset()//开始前先重置一下计时器
          this.$refs.sportTime.start()//计时开始
          this.motionId = res.data.id
        }
        this.startLoading = false
        this.$toast(res.msg);
      })
    },
    endMoving () {
      if (this.$refs.sportTime.m < 2) {
        this.$toast('运动时间不能小于2分钟');
        return
      }
      this.endLoading = true
      sportEnd({ id: this.motionId }).then(res => {
        if (res.code == 0) {
          this.startShow = true
          this.$refs.sportTime.stop()//计时结束
          this.getNftInfo();
        }
        this.endLoading = false
        this.$toast(res.msg);
      })
    },
    /* 获取当次运动数据 */
    getSportdata () {
      Sportdata().then(res => {
        if (res.code == 0) {
          this.motionData = res.data
        }
      })
    },
    //查询nft详情
    getNftInfo () {
      getWatchInfo().then(resu => {
        if (resu.code == 0) {
          this.sportData = resu.data.data
        }
      })
    },
    /* 切换nft */
    nftChange (nft_id, status = null) {
      if (status) {// status = 1 初始化或者刷新页面 就不调用切换接口
        this.getNftInfo();
      } else {// status = null 手动页面切换卡片 调用切换接口
        nftSwitch({
          nft_id
        }).then(res => {
          if (res.code == 0) {
            this.changeNftId = nft_id
            this.list = []
            this.page = 1
            this.loading = true
            this.finished = false
            this.onLoad()
            this.getNftInfo();
          }
        })
      }
    },
    onLoad () {
      getMyNftList({
        page: this.page,
        limit: this.limit,
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data.data)
          this.loading = false;
          // if (res.data.page.total_page <= res.data.page.current_page) {
          //   this.finished = true;
          // }
          if (res.data.data.length < this.limit) {
            this.finished = true;
          }
          if (res.data.page.current_page == 1) {
            nftSportState().then(resu => {
              if (resu.code == 0) {
                if (resu.data.data.id) {
                  //之前有运动过
                  this.nftChange(resu.data.data.id, 1);
                  this.changeNftId = resu.data.data.id;
                  console.log('已经运动的开始时间-----', resu.data.start_time);
                  console.log('服务器当前时间-----', resu.data.now_time);
                  this.motionId = resu.data.data.id
                  let startDuration = resu.data.now_time - resu.data.start_time //已经运动的时长(当前这一次运动)
                  this.$refs.sportTime.start(startDuration)
                  this.startShow = false;
                } else {
                  //之前没有运动过
                  if (this.list.length <= 0) return;

                  this.nftChange(this.list[0].id, 1);
                  this.changeNftId = this.list[0].id;
                  this.startShow = true;
                }
              }
            })
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    getUserInfo () {
      userInfo({}, localStorage.getItem('Token')).then(res => {
        if (res.code == 0) {
          window.localStorage.setItem('userInfo', JSON.stringify(res.data))
          window.localStorage.setItem('langue', res.data.langue)
          this.userInfo = res.data
          this.avatarUrl = res.data.avatar
        }
      })
    },
    /* 多语言弹窗打开 */
    setLang () {
      if (this.radio.length == '') {
        return
      }
      /* 设置多语言 */
      language({ language: this.radio }).then(res => {
        if (res.code == 0) {
          localStorage.setItem('langue', this.radio)
          this.$i18n.locale = this.radio
          this.show = false
          this.radio = ''
          this.$router.go(0)
        }

      })
    },
    /* 多语言弹窗关闭 */
    cancel () {
      this.show = false
      this.radio = ''
    },
    goTo (url) {
      this.$router.push({
        path: url
      })
    },
    clickDay (data) {
      console.log("选中了---", data); //选中某天
      let date = data.split('/')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]

      let time, year, month, day;
      year = date[0]
      month = date[1] < 10 ? '0' + date[1] : date[1]
      day = date[2] < 10 ? '0' + date[2] : date[2]
      time = year + '-' + month + '-' + day
      this.getPlanInfo(time);
    },
    cancelDate () {
      this.dateShow = false
    },
    confirmDate (data) {
      let d = new Date(data);
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let s = d.getDate();
      if (m < 10) m = "0" + m;
      if (s < 10) s = "0" + s;
      let datetime = y + "-" + m + "-" + s
      console.log("选中了月份----", datetime);
      // this.$refs.Calendar.ChoseMonth(datetime,false);//false第二个参数表示不选中当天
      this.$refs.Calendar.ChoseMonth(datetime);
      let date = datetime.split('-')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]
      this.dateShow = false
      this.getPlanInfo(datetime);
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.getUserInfo();
        // this.onLoad();
        this.getPlanInfo();
        this.getSportdata()
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>
<style lang="less" scoped>
.braceletHome {
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
  background: url(~@/assets/bracelet_img/14.png);
  background-size: 120%;
  background-position: top;

  background-repeat: repeat-y;
  color: #ffffff;
  .topTitle {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: url(~@/assets/bracelet_img/20.png) no-repeat;
    background-size: 150% 100%;
    background-position: center;
    position: relative;
    img {
      width: 62px;
      height: 62px;
      margin-right: 8px;
      border-radius: 50%;
    }
    .info {
      max-width: 85%;
      .name {
        font-family: 'KenyanCoffeeRg-BoldItalic';
        font-size: 30px;
        max-width: 100%;
     //    overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .id {
        font-family: 'AeroMaticsItalic';
        font-size: 12px;
      }
    }
  }
  .tupian {
    width: 55%;
    height: 15px;
  }
  .box {
    padding: 15px 32px 90px;
    .rank_integral {
      width: 100%;
      height: 100px;
      background: url(~@/assets/bracelet_img/22.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 15px;
      margin-bottom: 15px;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 5px 5px 0 10px;
        .one {
          display: flex;
          .rank {
            font-family: '071-SSRuiFengTi';
            font-size: 24px;
            color: #6480f3;
            margin-right: 70px;
          }
          .label {
            height: 19px;
            line-height: 19px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            padding: 0 11px;
            color: #2b2b2b;
            background: #8ff9f6;
            border-radius: 10px;
          }
        }
        .two {
          display: flex;
          .integral {
            font-family: '071-SSRuiFengTi';
            font-size: 24px;
            color: #2b2b2b;
            margin-right: 18px;
          }
          .label {
            height: 19px;
            line-height: 19px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            padding: 0 11px;
            background: #fff;
            border-radius: 10px;
          }
        }
      }
      .btm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'KenyanCoffeeRg-BoldItalic';
        color: #2b2b2b;
        text-align: center;
        .left {
          width: 50%;
          font-size: 40px;
        }
        .right {
          width: 50%;
          font-size: 25px;
        }
      }
    }
    .watch {
      width: 100%;
      border-radius: 15px;
      background: #fff;
      position: relative;
      margin-bottom: 15px;
      padding: 17px 0;
      .label {
        position: absolute;
        right: 5px;
        top: 8px;
        background-color: #2b2b2b;
        border-radius: 15px;
        padding: 4px 14px;
        font-family: 'TsangerYuYangT-W02';
        font-size: 12px;
      }
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
        color: #2b2b2b;
        padding: 2px 10px 5px;
        margin-bottom: 16px;
      }
      .watchName {
        font-family: 'REEJI-PinboGB-Flash';
        font-size: 24px;
        color: #2b2b2b;
        padding-left: 9px;
      }
      .tongbu {
        padding-left: 11px;
        font-family: 'TsangerYuYangT-W02';
        font-size: 12px;
        color: #2b2b2b;
        margin-top: -3px;
      }
      .btn_box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .btn {
          display: flex;
          margin-left: 16px;
          border-radius: 15px 3px 15px 3px;
          border: solid 2px #7f7f7f;
          padding: 2px 38px;
          font-family: '071-SSRuiFengTi';
          font-size: 24px;
          color: #2b2b2b;
        }
        .tongbudata {
          background-color: #8ff9f6;
          margin-bottom: 14px;
          margin-top: 25px;
        }
        .disconnect {
          background-color: #6480f3;
        }
      }
      // .img{
      //   width: 125px;
      //   height: 125px;
      //   position: absolute;
      //   right: 0;
      //   bottom: 0;
      // }
      .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding: 0 10px;
        max-height: 300px;
        overflow-y: auto;
        /deep/.van-list__finished-text {
          width: 100%;
          text-align: center;
        }
        .item {
          width: 32.5%;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #2b2b2b;
          border: 2px solid #fff;
          border-radius: 8px;
          margin-bottom: 4px;
          padding-bottom: 6px;
          margin-right: 1%;
          // .new{
          //   width: 100%;
          //   background-image: linear-gradient(115deg, #a7f264 0%, #2b2b2b 95%),
          //   linear-gradient(#2b2b2b, #2b2b2b);
          //   background-blend-mode: normal, normal;
          //   padding: 2px 6px;
          //   margin-bottom: 7px;
          //   font-family: 'REEJI-PinboGB-Flash';
          //   font-size: 10px;
          //   color: #2b2b2b;
          //   border-radius: 8px 8px 0 0;
          // }
          .name {
            font-family: 'AeroMaticsBoldItalic';
            font-size: 15px;
          }
          .cocobern {
            font-family: 'AeroMaticsBold';
            font-size: 10px;
            color: #f4f2f4;
            text-align: center;
          }
          .img {
            width: 85px;
            height: 85px;
          }
          // .addr{
          //   width: 100%;
          //   display: flex;
          //   align-items: center;
          //   margin: 5px 0;
          //   .tiao{
          //     width: 7px;
          //     min-width: 7px;
          //     height: 9px;
          //     background-color: #a7f264;
          //   }
          //   .text{
          //     width: 100%;
          //     font-family: 'AeroMaticsItalic';
          //     font-size: 10px;
          //     color: #ffffff;
          //     overflow: hidden;
          //     white-space: nowrap;
          //     text-overflow: ellipsis;
          //     -o-text-overflow: ellipsis;
          //   }
          // }
          // .price{
          //   width: 100px;
          //   background-color: #ffffff;
          //   border-radius: 8px;
          //   padding: 2px 0;
          //   text-align: center;
          //   font-family: 'AeroMaticsDisplayItalic';
          //   font-size: 10px;
          //   color: #2b2b2b;
          // }
          // .buy{
          //   font-family: 'REEJI-PinboGB-Flash';
          //   font-size: 30px;

          //   color: transparent;
          //   background: -webkit-linear-gradient(left bottom, #38F8D4, #42E97F);//#58FFD6
          //   /*  Safari 5.1 到 6.0 */
          //   background: -o-linear-gradient(top right, #38F8D4, #42E97F);
          //   /*  Opera 11.6 到 12.0 */
          //   background: -moz-linear-gradient(top right, #38F8D4, #42E97F);
          //   /*  Fx 3.6 到 15 */
          //   background: linear-gradient(to top right,#38F8D4, #42E97F);
          //   /* 标准语法（必须是最后一个） */
          //   background-clip: text;
          //   -webkit-background-clip: text;
          // }
        }
        .item:nth-child(3n) {
          margin-right: 0;
        }
        .item_act {
          border: 2px solid #8ff9f6;
        }
      }
    }
    .distance {
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      background-color: #ffffff;
      border-radius: 15px;
      padding: 17px 0 26px;
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
        color: #2b2b2b;
        padding: 2px 10px 5px;
        margin-bottom: 15px;
      }
      .bg {
        width: 100%;
        height: 101.25px;
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -60px;
        .item {
          text-align: center;
          margin-bottom: 22.5px;
          .num {
            font-family: 'KenyanCoffeeRg-BoldItalic';
            font-size: 37.5px;
            color: #2b2b2b;
          }
          .row {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: '071-SSRuiFengTi';
            font-size: 22.5px;
            color: #6111a8;
            img {
              width: 15px;
              height: 15px;
              margin-right: 3.75px;
            }
          }
        }
        .btn {
          border-radius: 15px 3px 15px 3px;
          border: 2px solid #7f7f7f;
          padding: 3px 0px;
          font-family: '071-SSRuiFengTi';
          font-size: 30px;
          text-align: center;
          color: #2b2b2b;
          width: 200px;
        }
        .top_btn {
          background-color: #40e9ec;
          margin-bottom: 15px;
        }
        .btm_btn {
          background-color: #6480f3;
        }
      }
    }
    .datePreset {
      width: 100%;
      background-color: #ffffff;
      border-radius: 15px;
      padding: 17px 0 26px;
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
        color: #2b2b2b;
        padding: 2px 10px 5px;
      }
      .box {
        padding: 17px 15px 26px;
        .calendar {
          width: 100%;
          box-shadow: 0px 0px 11.25px 3px rgba(0, 0, 0, 0.1);
          border-radius: 13px;
          .braceletHomeTitle {
            padding: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'AeroMaticsDisplayBold';
            font-size: 22px;
            color: #2b2b2b;
            border-bottom: 2px solid #94f9f6;
            img {
              width: 18.75px;
              height: 12px;
            }
          }
          .wh_container {
            /deep/.wh_content_all {
              background-color: #fff;
              border-radius: 0 0 13px 13px;
            }
            /deep/.wh_item_date {
              color: #2b2b2b;
            }
            // /deep/.wh_item_date:hover{
            //   background-color: #7741FA;
            //   border-radius: 7.5px;
            //   color: #fff;
            // }
            /deep/.wh_top_tag {
              color: #2b2b2b;
            }
            /deep/.wh_top_changge li {
              color: #2b2b2b;
            }
            /deep/.wh_top_changge {
              display: none;
            }
            /deep/.wh_other_dayhide {
              color: #bfbfbf;
            }
            /deep/.wh_chose_day {
              background-color: #7741fa;
              border-radius: 7.5px;
              color: #fff;
            }
            /deep/.wh_isToday {
              background-color: #8ff9f6;
              border-radius: 7.5px;
            }
          }
        }
      }
      .time {
        padding-left: 25px;
        .braceletHomeTitle {
          font-family: 'AeroMaticsDisplayBold';
          font-size: 24px;
          color: #2b2b2b;
          margin-bottom: 18px;
        }
        .item {
          display: flex;
          .line {
            width: 5px;
            height: 76px;
            background-color: #0ed5d9;
            margin-right: 14px;
          }
          .right {
            .text {
              font-family: 'TsangerYuYangT-W05';
              font-size: 18px;
              color: #2b2b2b;
            }
            .dt {
              font-family: 'AeroMaticsItalic';
              font-size: 18px;
              color: #6111a8;
            }
          }
        }
        .mar_btm_15 {
          margin-bottom: 15px;
        }
      }
      .report {
        margin-top: 10px;
        text-align: end;
        padding: 5px 15px;
        background-image: linear-gradient(115deg, #ffffff 0%, #7736fe 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 20px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .footBtm {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 22.5px;
    .left {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 0 3px 3px 0;
      padding: 3px 3px 3px 0;
      margin-right: 3px;
      .box {
        padding: 3px 10px 3px 7.5px;
        background-color: rgba(43, 43, 43, 0.9);
        border-radius: 0 3px 3px 0;
        border: solid 2px #7736fe;
        border-left: none;
        display: flex;
        img {
          width: 26px;
          height: 18.75px;
        }
      }
    }
    .right {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 3px 26.25px 3px 3px;
      padding: 3.75px 20px 3.75px 7.5px;
      display: flex;
      align-items: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18.75px;
      color: #2b2b2b;
      .ask {
        background-color: #5c65cb;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        width: 140px;
        text-align: center;
        margin-right: 7.5px;
      }
      .invite {
        background-image: linear-gradient(#a7f264, #a7f264),
          linear-gradient(#5c65cb, #5c65cb);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        width: 140px;
        text-align: center;
      }
    }
  }
}
.navto {
  position: absolute;
  right: 0;
  width: 33px !important;
  height: 33px !important;
}
.navto1 {
  position: absolute;
  right: 35px;
  width: 33px !important;
  height: 33px !important;
}
.pop-up {
  color: #000;
  border-radius: 10px;
  width: 280px;

  .pop-up-box {
    padding: 10px;
    .pop-box-radio {
      padding: 5px;
    }
  }
  .pop-up-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    .pop-btn-btn {
      border: 0;
      background-color: #fff;
      width: 40%;
    }
    .btn-determine-col {
      color: #ee0a24;
    }
    .btn-cancel-col {
      color: #b6b6b6;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  /deep/.van-popup--bottom {
    width: 430px;
    left: calc(50% - 215px);
  }
}
</style>
